
























































































import { Vue, Component, Emit } from "vue-property-decorator";
import { CustomInput } from "@components";

interface Charges {
  pos: string;
  accountNo: string;
  fees: string;
  avDailyBalance: string;
  avDailyTransactionCount: string;
  avDailyTransactionAmount: string;
  sales: string;
}
@Component({
  components: {
    CustomInput,
  },
})
export default class FeesCharges extends Vue {
  charges: Charges = {
    pos: "",
    accountNo: "",
    fees: "",
    avDailyBalance: "",
    avDailyTransactionCount: "",
    avDailyTransactionAmount: "",
    sales: "",
  };
  @Emit("getValue")
  created() {
    return 4;
  }
}
